import {AbstractComponent, ComponentLoader} from "../../../../AbstractComponent";


export class ProtectedButton extends AbstractComponent
{
    public static selector = 'protected-button';

    protected buttonData: any;
    protected buttonProtectionTimeoutNumber: number = 15000;
    protected inflight: boolean = false;

    init() {
        super.init();
        this.getComponentElement().on('click', (event) => {
            if (this.inflight) {
                event.preventDefault();
                return false;
            }

            this.inflight = true;
            this.setButtonToProcessing(true);

            setTimeout(() => {
                this.setButtonToProcessing(false);
                this.inflight = false;
            }, this.buttonProtectionTimeoutNumber);
        });
    }

    protected setButtonToProcessing(setButtonToProcessing: boolean = true, button: any = null, disableButton: boolean = true): this {
        // save button data if nothing stored in property
        if (!this.buttonData)
        {
            let loadingContent = this.componentElement.attr('loading-content');
            let original: string = '';
            loadingContent = JSON.parse(loadingContent).content;

            if (this.componentElement.find('[text]').length !== 0)
            {
                original = this.componentElement.find('[text]').html();
            }
            else
            {
                original = this.componentElement.html();
            }

            this.buttonData = {
                "original": original,
                "processing": loadingContent
            };
        }

        if (setButtonToProcessing)
        {
            if (this.componentElement.find('[text]').length !== 0)
            {
                this.componentElement.find('[text]').html(this.buttonData.processing);
            }
            else
            {
                this.componentElement.html(this.buttonData.processing);
            }

            this.componentElement.attr('processing', true);

            if (disableButton)
            {
                this.componentElement.prop("disabled", true);
            }

            this.getComponentElement().find(':input').attr('readonly', true);
        }
        else
        {
            if (this.componentElement.find('[text]').length !== 0)
            {
                this.componentElement.find('[text]').html(this.buttonData.original);
            }
            else
            {
                this.componentElement.html(this.buttonData.original);
            }

            this.componentElement.removeAttr('processing');
            this.componentElement.prop("disabled", false);
            this.getComponentElement().find(':input').removeAttr('readonly');
        }

        return this;
    }
}

new ComponentLoader(ProtectedButton);
